export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POZOR: Bez jedinečného návratového kódu nelze váš balíček identifikovat."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bankovního účtu začíná s %s (příklad: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ujistěte se, že jste v balení uvedli své jedinečné číslo stížnosti s produktem. Vaše jedinečné číslo stížnosti je"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentář"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poškozený výrobek"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdravy"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahoj!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace nejsou vyžadovány"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný důvod"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po obdržení vašeho vrácení do našich kanceláří bude zaslán e-mail na adresu uvedenou ve formuláři stížnosti, kde Vás budeme informovat o nejnovějších aktualizacích procesu vrácení."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo objednávky"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplaceno na našich webových stránkách, a ne s doručovací službou /kurýrem"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalita výrobků"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhrada produktu"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postup vrácení produktu"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces vrácení produktu"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod stížnosti"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení peněz"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulář vrácení peněz"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení produktu je rychlé a snadné"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrácení produktu je rychlé a snadné"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte typ stížnosti"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odešlete balíček na"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balíček odeslat na následující adresu:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Něco se pokazilo, zkuste to znovu později nebo kontaktujte naši podporu"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat formulář"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bankovního účtu začíná s %s (příklad: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postup vrácení byl právě přesměrován na vaši e-mailovou adresu:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spolu s produktem, který má být vrácen, zadejte do balíčku jedinečný zpáteční kód.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh platby"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Když obdržíme balíček v naší pobočce, budete o průběhu reklamace informováni na Vaši e-mailovou adresu, kterou jste uvedli při podání reklamního formuláře."])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špatný produkt"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špatné množství"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete vytisknout již dokončené informace, které vám pomohou vrátit produkt"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Již jste odeslali žádost o vrácení. Buďte trpěliví"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V příloze jste obdrželi soubor PDF pro postup vrácení."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše jedinečné zpáteční číslo je"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo stížnosti"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příklad"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křestní jméno"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukce"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příjmení"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciever"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesílatel"])}
}